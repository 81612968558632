import fetcher from "./fetcher";

const downloadReviewsReport = (dates) => {
	return fetcher({
		url: `/api/v3/admin/reviews/download-report?startDate=${dates.startDate}&endDate=${dates.endDate}`,
		method: "GET"
	});
};

const useRunSync = async (torun, data) => {
	return fetcher({
		url: `/api/v3/admin/sync/${torun}`,
		method: "POST",
		data
	});
};

/**
 *	@param {string[]} emails
 * */
const syncWithBilling = async (emails) => {
	return fetcher({
		url: "/api/v3/admin/billing/saveBillingLead",
		method: "POST",
		data: {
			emails
		}
	});
};

export { useRunSync, downloadReviewsReport, syncWithBilling };
